import { Button } from "../../../shared/formElements/button";
import {
  ButtonGroupContainer,
  Line,
  SectionContainer,
} from "../../../shared/Layout.styles";
import { Markdown } from "../../../shared/markdown";
import { termsConstants } from "../../../utils/constants/terms";
import styled from "styled-components/macro";

export const Image = styled.img`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const Terms = () => {
  const { subHeading, fgaTermsText, fgaTermsText1 } = termsConstants;

  return (
    <>
      <Line />
      <SectionContainer>
        <Markdown children={subHeading} align="center" />
        <Markdown
          children={fgaTermsText}
          align="left"
          className="fujitsuTermsText"
        />
        <Image src="https://ik.imagekit.io/vaultmp/Screen_Shot_2022-02-02_at_11.25.28_am_1AcAfo4fw.png?ik-sdk-version=javascript-1.4.3&updatedAt=1643761559507" />
        <Markdown
          children={fgaTermsText1}
          align="left"
          className="fujitsuTermsText"
        />
      </SectionContainer>
    </>
  );
};
