import styled from "styled-components/macro";
import { ReactMarkdownProps } from "react-markdown";
import ReactMarkdownWithHtml from "react-markdown/with-html";

export interface IMarkdownTypeProps extends Omit<ReactMarkdownProps, "types"> {
  weight?: "light" | "regular" | "medium" | "bold";
  align?: "left" | "center" | "right";
}

export const MarkdownElement = styled(
  ReactMarkdownWithHtml
)<IMarkdownTypeProps>`
  padding-bottom: 10px;
  text-align: ${({ align }) =>
    align === "left"
      ? "left"
      : align === "center"
      ? "center"
      : align === "right"
      ? "right"
      : "inherit"};
  a {
    display: inline-block;
    padding: 0 5px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary.default};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    font-size: ${({ theme }) => theme.font.size.small};
  }
  &.termsConditionsText > p > a {
    font-size: ${({ theme }) => theme.font.size.small};
    padding: 0;
  }
  &.termsConditionsText {
    padding: 0;
  }
  &.divider > p {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.colors.primary.default};
  }
  &.successText > h4,
  &.termsInstructionsText > h4 {
    font-weight: ${({ theme }) => theme.font.weight.regular};
    color: ${({ theme }) => theme.colors.text.subHeadingText};
    width: 70%;
    margin: 0 auto;
  }
  &.balanceText > h4 {
    font-weight: ${({ theme }) => theme.font.weight.regular};
    color: ${({ theme }) => theme.colors.text.subHeadingText};
  }
  &.subText > p {
    font-size: ${({ theme }) => theme.font.size.smallest};
  }
  &.validationText {
    padding-top: 5px;
  }
  &.validationText > p {
    font-size: ${({ theme }) => theme.font.size.smallest};
    color: ${({ theme }) => theme.colors.error};
  }
  &.validationText > p > a {
    font-size: ${({ theme }) => theme.font.size.smallest};
  }
  &.balanceValue > h2 {
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }
  &.footerLinkText > p {
    display: inline-block;
    padding: 0 5px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary.default};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    font-size: ${({ theme }) => theme.font.size.medium};
  }
  &.vaultTermsText > ol > li {
    color: ${({ theme }) => theme.colors.white.default};
  }
  &.fujitsuTermsText > ol > li {
    color: ${({ theme }) => theme.colors.black.default};
  }
  &.fujitsuTermsText > ol > li > p {
    color: ${({ theme }) => theme.colors.black.default};
    font-size: 16px;
  }
  &.fujitsuTermsText > p {
    color: ${({ theme }) => theme.colors.black.default};
    font-size: 16px;
  }
`;
