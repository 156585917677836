import { useState } from "react";
import { Button } from "../../../shared/formElements/button";
import * as Yup from "yup";
import {
  Form,
  FormContainer,
  FormSectionContainer,
  SectionContainer,
  ButtonGroupContainer,
  LoadingSpinner,
  ImageSplitFormSection,
  Line,
} from "../../../shared/Layout.styles";
import { Markdown } from "../../../shared/markdown";
import { balanceConstants } from "../../../utils/constants/balance";
import { activateCardConstants } from "../../../utils/constants/activate";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../../shared/formElements/input";
import { BalanceTable } from "./sections/BalanceTable";
import { useContextDispatch, useContextState } from "../../context/context";
import axios from "axios";
import { IconSectionCard } from "../../../shared/iconSectionCardSection";
import { globalConstants } from "../../../utils/constants/global";


const { activationCode } = activateCardConstants.validations;

export const Balance = () => {
  const dispatch = useContextDispatch();
  const { transactions, loading } = useContextState();

  const [serverErrors, setServerErrors] = useState("");
  const [showBalance, setShowBalance] = useState(false);

  const { heading, subHeading, balanceText, balanceButtonText, defaultValue } =
    balanceConstants;
  const { expiredCardMessage } = globalConstants;

  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
    defaultValues: {
      activationCode: "",
    },
  });

  // cardNumber: 'VLF8023QB', dummydata
  const onSubmit = async (formData: any) => {
    try {
      dispatch("START_LOADING");
      const data = {
        cardNumber: formData.activationCode,
      };
      const res = await axios.post("/CardTransactions", data);
      dispatch("SET_BALANCES", res.data);
      setShowBalance(true);
      dispatch("STOP_LOADING");
    } catch (err: any) {
      if (err.response.status === 404) {
        setServerErrors("Card Not Found");
        console.log("err", err);
      } else if (err.response.status === 500) {
        setServerErrors(err.response.data.message);
      } else if (err.response.status === 700) {
        setServerErrors(expiredCardMessage);
      } else {
        setServerErrors("Card Not Found");
      }
      dispatch("STOP_LOADING");
    }
  };
  const {
    control,
    formState: { errors },
  } = methods;

  return (
    <>
      <Line />
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <SectionContainer>
            <Markdown children={heading} />
            {!showBalance && (
              <Markdown children={subHeading} className="balanceText" />
            )}
            <FormContainer>
              {showBalance ? (
                <BalanceTable transactions={transactions} />
              ) : (
                <>
                  <FormSectionContainer className="balanceSection">
                    <Controller
                      render={(props) => (
                        <Input
                          {...props}
                          type="text"
                          label="Claim Activation Code*"
                          fieldName="activationCode"
                          validation={
                            errors?.activationCode?.message || serverErrors
                          }
                          width="100%"
                        />
                      )}
                      name="activationCode"
                      control={control}
                      defaultValue={defaultValue.activationCode || ""}
                    />
                    <Markdown children={balanceText} className="subText" />
                    <ButtonGroupContainer>
                      <Button
                        onClick={() => {
                          methods.handleSubmit(onSubmit);
                        }}
                      >
                        {loading ? <LoadingSpinner show /> : balanceButtonText}
                      </Button>
                    </ButtonGroupContainer>
                  </FormSectionContainer>
                  <FormSectionContainer>
                    {process.env.REACT_APP_CARD_OR_ICONS === "CARD" ? (
                      <ImageSplitFormSection
                        src={process.env.REACT_APP_CARD_FRONT ?? ""}
                      />
                    ) : (
                      <IconSectionCard />
                    )}
                  </FormSectionContainer>
                </>
              )}
            </FormContainer>
          </SectionContainer>
        </Form>
      </FormProvider>
    </>
  );
};
//
const validationSchema = Yup.object().shape({
  activationCode: Yup.string()
    .required(activationCode.required)
    .matches(/^[a-zA-Z0-9_.-]*$/, {
      message: activationCode.format,
      excludeEmptyString: true,
    }),
});
