export const termsConstants = {
  subHeading: `# Terms & Conditions`,
  termsInstructionsText: `#### Follow the link below to read the full promotional terms & conditions`,
  termsButtonText: `Read Terms`,
  fgaTermsText: `### FUJITSU SUMMER COOLIT PROMOTION 2022 TERMS AND CONDITIONS<br><br>
  
#### ELIGIBILITY CONDITIONS AND PROMOTIONAL PERIOD

1. Information on how to Claim (defined below) and details of Rewards (defined below) form part of these Terms and Conditions. Participation in this promotion is deemed acceptance of these Terms and Conditions. The offer in this promotion is not valid in conjunction with any other offer unless specified otherwise.
2. The "Promotional Purchase Period" commences 9:00am AEDT on Tuesday 1st February 2022 and ends for purchases at 4:59pm AEDT on Thursday 31st March 2022. Final Claims close at 4:59pm AEST on Tuesday 31st May 2022. No Claims will be accepted after this date under any circumstances.
3. The promotion is only open to Australian residents ("Claimant").
4. Employees and immediate families of the Promoter and its agencies including participating retailers and dealers associated with this promotion are ineligible to Claim. “Immediate family” means any of the following: spouse, ex-spouse, de-facto spouse, child, or stepchild (whether natural or by adoption), parent, stepparent, grandparent, step grandparent, uncle, aunt, niece, nephew, brother, sister, stepbrother, stepsister, or 1st cousin. Purchases by, for and in the name of trusts, companies, businesses, commercial or residential developers/developments and purchases by builders, subcontractors, installers/resellers and their immediate family, churches, not-for-profit organisations, sporting clubs and donations are not eligible. The purchaser is considered as the payer for the Eligible Products (defined below) as shown on the submitted proof of payment document.
5. Claimants under 18 years of age must have parental/guardian approval to enter and further, the parent/guardian of the Claimant must read, understand, and accept the full Terms and Conditions available at www.fujitsugeneral.com.au/promotions. Parents/guardians may be required by the Promoter to enter into a further agreement as evidence of consent to the minor participating in this promotion. If a Claimant is under the age of 18 years, the Reward will be awarded to the Claimant’s nominated parent or guardian on the Claimant’s behalf.
6. Purchases must be from any participating Fujitsu retailer or dealer in Australia. Purchases must be in the Claimant’s personal name only.
7. Purchases must only be for domestic and residential use and non-residential applications are excluded. Purchases from registered builders or commercial or residential developers and other entities that are not individuals as described in point 4 above are excluded from this promotion and will be ineligible if submitted.<br><br>

#### HOW TO CLAIM

8. To Claim a Reward, the Claimants must:<br><br>
a. purchase a product as detailed in the “eligible product list” noted below and also in the FAQ’s section found at www.fujitsugeneral.com.au/promotions (“Eligible Product”) during the Promotional Purchase Period and keep their original proof of purchase (i.e. invoice or tax invoice, "Proof of Purchase"); and

b. by 4:59pm AEST on Tuesday 31st May 2022 complete the Claim form online at www.fujitsugeneral.com.au/promotions, inputting all of the requested details, including but not limited to, personal details, residential or delivery address, the outdoor model and serial number and indoor model number of the Eligible Product purchased, upload a copy of their Proof of Purchase and proof of payment (i.e. a transaction receipt such as an EFT receipt, a bank statement or credit card slip showing the purchase, etc.) for the Eligible Product purchased,("Claim").<br><br>
The Promoter is not responsible if a Claimant’s mobile device/desktop is not sufficiently capable for the purpose of submitting a Claim, including having the requisite photograph capability.

In the case of Claimants who have purchased an Eligible Product for their domestic/residential use during the Promotional Purchase Period, but have not yet had it delivered or installed, and therefore cannot locate the outdoor serial number in time to make a valid Claim, they must contact the Promoter in writing via www.fujitsugeneral.com.au/help-centre/contactus prior to 4:59pm AEST on Tuesday 31st May 2022 so that alternative arrangements for reward issue can be made. Claimants will have until 4:59pm AEST on Thursday 30th June 2022 to lodge any additional or correct documentation should the Promoter deem the original Claim not valid. The Promoter will not accept additional documentation submitted after this date.

For the purpose of this promotion, “purchase” is defined as a fully paid Eligible Product, with zero balance owing, with payment having been fully made within the Promotional Purchase Period. If the Claimant has paid in cash, this must be clearly displayed on the invoice.<br><br>
#### ELIGIBLE PRODUCT LIST`,
  fgaTermsText1: `
9. Claimants must retain their original Proof of Purchase and proof of payment documents (where applicable) for all Claims. Failure to produce the required documentation for all Claims when requested may, in the absolute discretion of the Promoter, result in invalidation of ALL of a Claimant’s Claims and forfeiture of any right to a Reward. Purchase receipt(s) and tax invoice(s) must clearly show only the Claimant‘s full personal name, the residential installation address, the Eligible Product model purchased and specify the store of purchase and that the purchase was made during the Promotional Purchase Period but prior to any Claim by the Claimant.
  
10. The Claimant’s full personal name must appear on all proof of purchase documentation provided to verify ownership and entitlement to Claim a Reward. Uploaded files must be submitted in PDF, JPEG or PNG format and must not exceed 4MB file size. If a Claimant is not able to upload documents, then they must be able to print off a copy of their Claim form and then fax together with their proof of purchase and proof of payment documents to the Promoter’s fax number (+61 2 8079 0747). If the Eligible Product is paid for by cash, the Promoter may ask for evidence of this transaction from the supplier of the product(s) before fulfilling the Claim.
11. Multiple Claims are permitted, subject to the following:<br><br>

a. only one (1) Claim permitted per Eligible Product purchased during the Promotional Purchase
Period; and

b. each Claim must be submitted separately and in accordance with these Terms and Conditions.
<br><br>
At the sole discretion of the Promoter, Claim forms completed by third parties on behalf of Claimants may be rejected. Initial and most correspondence from the Promoter, its agents, contractors, service providers or prize suppliers to Claimants will be via email to the email address and via SMS to the mobile phone number provided on the Claimant’s Claim form however we may also do a follow-up via phone call to the number provided on the Claim form. If the Claimant requires any assistance in relation to completion or submission of the Claim form, they can contact the customer call line on 1800 431 338.<br><br>

12. If for any reason the product is returned post initial purchase, the relevant Reward will be forfeited (unless the product is defective).

13. The Promoter reserves the right, at any time, to verify the validity of Claims and Claimants (including a Claimant’s identity, age, and place of residence) and reserves the right, in its sole discretion, to disqualify any individual who the Promoter has reason to believe has breached any of these Terms and Conditions, tampered with the Claim process or engaged in any unlawful or other improper misconduct calculated to jeopardise fair and proper conduct of the promotion. Errors and omissions may be accepted at the Promoter's discretion. Failure by the Promoter to enforce any of its rights at any stage does not constitute a waiver of those rights. The Promoter's legal rights to recover damages or other compensation from such an offender are reserved. If there is a dispute as to the identity of a Claimant, the Promoter reserves the right, in its sole discretion, to determine the identity of the Claimant.

14. Any Claim which is incomplete, indecipherable, invalid or does not comply with the Terms and Conditions outlined will not be accepted and is ineligible for a Reward. The Promoter will notify Claimants by email upon approval of their Claim, if further information is required or upon rejection of their Claim.

<br><br>
#### REWARD CONDITIONS<br><br>

15. Each valid Claim received will entitle that Claimant to the gift of one (1) Reward as follows:

**i. CoolingOnlySplitSystems**

a. Claimants who purchase an eligible Fujitsu Cooling only Inverter Split System Air Conditioner with a rated cooling capacity of 3.5kW or below will be eligible for a $100 Reward;

b. Claimants who purchase an eligible Fujitsu Cooling only Inverter Split System Air Conditioner with a rated cooling capacity above 3.5kW will be eligible for a $150 Reward;
(each, a "**Reward**").

16. Claimants can elect to receive their Reward as either:
<br>
a. a Vault Pays-enabled Fujitsu Digital Prepaid Mastercard® delivered within 1 week of Claim
approval via SMS to the Australian mobile phone number submitted with their Claim ("Digital
Reward"); or

b. a physical Fujitsu Prepaid Mastercard delivered within 4 weeks of Claim approval via mail to the
delivery address submitted with their Claim ("Physical Reward").

17. In the case of Claimants who have multiple Eligible Product purchases approved under the one Claim ID,
the Promoter reserves the right to load the accumulated value of each Reward they are eligible for on to a single Vault Pays-enabled Fujitsu Digital Prepaid Mastercard in the format chosen by the Claimant, rather than one per valid Eligible Product purchased.

18. Claimants must allow up to 4 weeks for validation after submission of a Claim. The Claimant will be sent an email to the email address provided on the Claim form with notification of Claim approval.

19. The Promoter will not be responsible for any Reward which is lost, late or misdirected including by reason of the misstatement or illegibility of the mobile phone number or delivery address of the Claimant in the Claim form forming part of their Claim or the failure of a Claimant to notify the Promoter of a change in mobile phone number or delivery address of the Claimant. Use of the Reward is subject to all the Terms and Conditions located at www.vaultps.com.au/terms. Use of the prepaid physical Reward is subject to all the Terms and Conditions located at fgacoolitcard22.com.au.<br><br>

#### DIGITAL REWARD ACTIVATION AND REDEMPTION <br><br>
  
20. Redemption of the Digital Reward is subject to the terms and conditions of Vault Payment Solutions including activating the Digital Reward before the specified expiry date which will be sent to the Claimant when their Digital Reward activation code is sent by SMS to the mobile phone number they provided when entering the promotion.

21. The Digital Reward needs to be activated by following the link to access the Vault Payments Application via the App store that is sent in the SMS with their Digital Reward activation code. By clicking on this link in the SMS, the Claimant will automatically download and open the Vault Payments App, entering and validating the Claimant’s mobile number and activation code. The Claimant is then required to:

a. create a password (minimum of 6 characters) if they are a first time user of the Vault Payments App.

b. registered users of Vault Payments App already, enter their existing password,the Claimant may then add the card to the wallet on their phone.

22. Claimants can create a PIN for the Digital Prepaid Mastercard via the Vault Payments App as using the Digital Reward for purchases over $100 may require Claim of a PIN. Instructions on how to set up a PIN are found in the “How do I find my pin number?” FAQ located at www.vaultps.com.au/faq.

23. Digital Reward activation codes expire at 11:59pm AEST/AEDT (as applicable) on the stated expiry date of the activation code. The expiry date provides 3 months in which the Digital Reward activation code must be activated.

24. The Digital Reward is valid until the expiry date shown on the front of the card in the Vault Payments App (not less than 12 months from date of production) and cannot be used after expiry. At expiry, the remaining available balance will be forfeited.

25. Claimants who do not have a supported smart phone will be notified via a web page when they click on the link that was sent to them by SMS to activate their Vault Pays-enabled Fujitsu Digital Prepaid Mastercard. The web page will direct them to an online web form to facilitate provision of an alternate reward of a physical Fujitsu Prepaid Mastercard gift card upon request. Digital Rewards that are not activated or redeemed within the designated time frame included in the SMS sent with the code cannot be re-activated, extended or refunded in any way. The Promoter is not responsible should the Claimant fail to activate or redeem the Digital Rewards in time.

Customer support for the Vault Pays-enabled Fujitsu Digital Prepaid Mastercard is available at www.vaultps.com.au.

26. PHYSICAL REWARD ACTIVATION AND REDEMPTION

27.Redemption of the Physical Reward is subject to the terms and conditions available at fgacoolitcard22.com.au including activating the Physical Reward at least one (1) month before the expiry date printed on the front of the card.

28. Physical Rewards need to be activated at fgacoolitcard22.com.au or on 1800 431 338. To activate the Physical Reward, the card holder must provide the following details: First Name, Last Name, Email Address, Claim Activation Code (found on the back of the card) and Claim ID as registered for the Physical Reward and the Card Number issued to the Claimant. These details must match those in the submitted Claim.<br><br>

#### FORFEITURE, REPLACEMENT & LIMITATIONS <br><br>

29. Any unused balance of the Reward as of card expiry date will be forfeited.

30. If a Reward is unavailable, the Promoter, in its discretion, reserves the right to provide a substitute product or item to the equal value and/or specification.

31. The Reward is not transferable or exchangeable and cannot be taken as cash, unless otherwise specified. Any ancillary costs associated with redeeming the Reward are not included.<br><br>

#### GENERAL<br><br>

32. A Claim form may be subject to such follow up enquiries or investigations or security and verification checks as the Promoter determines to apply at the absolute discretion of the Promoter. The Claim form will be ineligible if the Claim form and/or the original or photocopied proof of purchase (valid tax invoice) and proof of payment is mutilated, illegible, stolen, forged, reconstructed, altered, incomplete or tampered with in any way, or if they fail any of the Promoter’s security and verification checks or if the Promoter in its absolute discretion determines that a Claimant is not an eligible Claimant or the Claim form does not comply with the Terms and Conditions as outlined, whether as a result of follow up, inquiry or investigation or otherwise.

33. The Promoter accepts no responsibility for late, lost, or misdirected mail, email, or SMS transmission due to circumstances beyond the Promoter’s reasonable control.

34. Each submitted Claim becomes the property of the Promoter, including any intellectual property rights.

35. If, for any reason, a Claimant does not take or redeem a Reward (or an element of the Reward) at or by the time stipulated by the Promoter, then the Reward (or that element of the Reward) will be forfeited.

36. The decision of the Promoter on all matters pertaining to this promotion is final. No correspondence will be entered into.

37. The Promoter accepts no responsibility for any tax implications that may arise from the offer. Independent financial advice should be sought. If for GST purposes this promotion results in any supply being made for non-monetary consideration, Claimants must follow the Australian Taxation Office’s stated view that where the parties are at arm’s length, goods and services exchanged are of equal GST inclusive market values.

38. Nothing in these Terms and Conditions limits, excludes, or modifies or purports to limit, exclude or modify the statutory consumer guarantees as provided under the Competition and Consumer Act, as well as any other implied warranties under the ASIC Act or similar consumer protection laws in the States and Territories of Australia (“Non-Excludable Guarantees”). Except for any liability that cannot by law be excluded, including the Non-Excludable Guarantees, the Promoter (including its respective officers, employees, and agents) excludes all liability (including negligence), for any personal injury; or any loss or damage (including loss of opportunity); whether direct, indirect, special, or consequential, arising in any way out of the promotion.

39. Except for any liability that cannot by law be excluded, including the Non-Excludable Guarantees, the Promoter (including its respective officers, employees, and agents) is not responsible for and excludes all liability (including negligence), for any personal injury; or any loss or damage (including loss of opportunity); whether direct, indirect, special, or consequential, arising in any way out of:

a. any technical difficulties or equipment malfunction (whether or not under the Promoter’s control);

b. any theft, unauthorised access or third party interference;

c. any Claim or Reward that is late, lost, altered, damaged or misdirected (whether or not after their receipt by the Promoter) due to any reason beyond the reasonable control of the Promoter;

d. any tax liability incurred by a Claimant; or

e. use/redemption of a Reward.

40. Retailers/dealers are not authorised to verify, pay or advise about, any Claim, the offer, or the promotion.

41. Any cost associated with accessing the promotional website is the Claimant’s responsibility and is dependent on the Internet service provider used. The use of any automated software or any other mechanical or electronic means that allows a Claimant to automatically Claim and enter repeatedly is prohibited and will render all Claims submitted by that Claimant invalid.

42. All Claimants acknowledge that the Promoter can rely on these Terms and Conditions even if the Promoter only learns of a person’s ineligibility after the Promoter has awarded a reward to the ineligible person. Payment of the reward value to the Promoter may be required by the Claimant if this occurs.

43. If this promotion is interfered with in any way or is not capable of being conducted as reasonably anticipated due to any reason beyond the reasonable control of the Promoter, including but not limited to technical difficulties, unauthorised intervention or fraud, the Promoter reserves the right, in its sole discretion, to the fullest extent permitted by law:

a. to disqualify any Claimant; or

b. to modify, suspend, terminate, or cancel the promotion, as appropriate.

44. By submitting a Claim in the promotion, Claimant’s consent to the Promoter using their name, likeness, image and/or voice (including photograph, film and/or recording of the same) in any media for an unlimited period without remuneration for the purpose of promoting this promotion (including any outcome), and promoting any products manufactured, distributed and/or supplied by the Promoter.

45. The Promoter collects personal information ("PI") in order to conduct the promotion and may, for this purpose, disclose such PI to third parties, including but not limited to agents, contractors, service providers and prize suppliers, such as Vault Payment Solutions Group Pty Ltd (ABN 66 632 373 105) and EML Payment Solutions Limited ABN 30 131 436 532. Submitting a Claim is conditional on providing this PI. The Promoter will collect, use and disclose such PI as set out in its Privacy Policy, which can be viewed at www.fujitsugeneral.com.au/privacy-policy In addition to any use that may be outlined in the Promoter’s Privacy Policy, the Promoter may, for an indefinite period, unless otherwise advised, use the PI for promotional, marketing, publicity, research and profiling purposes, including sending electronic messages or telephoning the Claimant. The Privacy Policy also contains information about how Claimants may opt out, access, update or correct their PI, how Claimants may complain about a breach of the Australian Privacy Principles or any other applicable law and how those complaints will be dealt with. All Claims become the property of the Promoter. This may include disclosures to organisations outside Australia including in places such as Singapore. In addition, where the Vault Payment Solutions Group Pty Ltd or EML Payment Solutions Limited handle PI on behalf of the Promoter, such PI will also be handled in accordance with their respective privacy policies, which can be viewed at www.vaultps.com.au/privacy-policy

46. As a condition of Claiming a Reward, the Claimant may be required to sign any legal documentation as and in the form required by the Promoter and/or Reward suppliers in their absolute discretion, including but not limited to a legal release and indemnity form. In the event a Claimant is under the age of 18, a nominated parent/legal guardian of such Claimant will be required to sign the legal documentation required under this clause on their behalf.

47. Any ancillary costs associated with redeeming the Vault Pays-enabled Fujitsu Digital Prepaid Mastercard are not included. The Vault Pays-enabled Prepaid Mastercard must be activated within 3 months of issue and is valid for 12 months after activation. At expiry of the Vault Pays-enabled Mastercard any unused balance will be forfeited. We will not give you notice prior to expiry. Card expiry and balance can be found on your mobile device in your digital wallet. All Rewards are issued by EML Payment Solutions Limited (ABN 30 131 436 532) AFSL 404131 pursuant to license by Mastercard Asia/Pacific Pte. Ltd. See www.vaultps.com.au/terms for terms and conditions. Mastercard and the circles design are registered trademarks of Mastercard International Incorporated.

48. This promotion is in no way sponsored, endorsed, or administered by, or associated with any social media platform, including Facebook, Instagram, and Twitter. Claimants provide their information to the Promoter and not to any social media platform. Claimants completely release any relevant social media platforms from any and all liability.

49. The Promoter is Fujitsu General (Aust.) Pty Limited (ABN 55 001 229 554), 1 Telopea Place, Eastern Creek NSW 2766.
`,
};
